import {makeVar, ReactiveVar} from "@apollo/client";
import {Step, qc, QC} from "../../qc";
import {Location, locations, Maybe} from "../../locations";

const findLocation = (values: Array<number>) => {
	console.log(values)
	const theater = locations.find((location) => values[0] === location.id)
	const auditorium = theater?.children?.find((location) => values[1] === location?.id)
	console.log(theater?.name)
	console.log(auditorium?.name)
	return {theater, auditorium}
}

export const statsForNerdsVar = makeVar(false);
export const themeVar = makeVar('light');
export const finalResultsVar : ReactiveVar<QC> = makeVar({})
export const currentStepVar:ReactiveVar<Step > = makeVar(qc[0])
export const hideImagesVar = makeVar(false)
export const locationVar= makeVar<{ theater: Location | undefined  , auditorium: Maybe<Location> }>(findLocation([]))