import React, {useRef, useState} from 'react';
import {
	ProCard,
	ProFormCascader,
	ProFormDependency,
	ProFormInstance,
	ProFormRadio,
	ProFormText,
	ProFormTextArea,
	StatisticCard,
	StepsForm
} from "@ant-design/pro-components";
import {inverseScoreWeight, qc, Step} from "./qc";
import ThumbDownIcon from "@2fd/ant-design-icons/lib/ThumbDown";
import ThumbsUpDownIcon from "@2fd/ant-design-icons/lib/ThumbsUpDown";
import ThumbUpIcon from "@2fd/ant-design-icons/lib/ThumbUp";
import {
	Affix,
	Avatar,
	Button,
	Card,
	Col,
	Descriptions,
	Drawer,
	Image,
	Layout,
	Menu,
	Row,
	Skeleton,
	Space,
	theme,
	Tooltip,
	Typography
} from "antd";
import Results from "./components/Results";
import HelpIcon from "@2fd/ant-design-icons/lib/Help";
import HomeIcon from "@2fd/ant-design-icons/lib/Home";
import {locations} from "./locations";
import Video3DIcon from "@2fd/ant-design-icons/lib/Video3D";
import ProjectorIcon from "@2fd/ant-design-icons/lib/Projector";
import ServerIcon from "@2fd/ant-design-icons/lib/Server";
import SurroundSoundIcon from "@2fd/ant-design-icons/lib/SurroundSound";
import PlayBoxIcon from "@2fd/ant-design-icons/lib/PlayBox";
import {useReactiveVar} from "@apollo/client";
import {currentStepVar, finalResultsVar, hideImagesVar, statsForNerdsVar} from "./apollo/reactiveVar";
import ArrowRightIcon from "@2fd/ant-design-icons/lib/ArrowRight";
import ArrowLeftIcon from "@2fd/ant-design-icons/lib/ArrowLeft";
import FileImageRemoveIcon from "@2fd/ant-design-icons/lib/FileImageRemove";
import FileImageIcon from "@2fd/ant-design-icons/lib/FileImage";
import FloatMenu from "./components/FloatMenu";

const {Header, Footer, Sider, Content} = Layout;

export const rateDescription = ['Bad', 'Just fine', 'Perfect'];


const findLocation = (values: Array<number>) => {
	console.log(values)
	const theater = locations.find((location) => values[0] === location.id)
	const auditorium = theater?.children?.find((location) => values[1] === location?.id)
	console.log(theater?.name)
	console.log(auditorium?.name)
	return {theater, auditorium}
}

function App() {

	const formRef = useRef<ProFormInstance>();
	const [open, setOpen] = useState(false);
	const [chartData, setChartData] = useState([{}]);
	const [results, setResults] = useState<Step[]>();
	const [currentStep, setCurrentStep] = useState(0);

// handle form submission
	const onFinish = async (values: Record<string, any>) => {
		// Log the form values
		console.log(values)

		// Merge data from the quality check (qc) steps
		const mergeData = qc.map(step => {
			// Update questions within each step
			const updatedQuestions = step.questions?.map(question => {
				// Get the result for the current question
				const result = (values[step.key] && values[step.key][question.key]) ? values[step.key][question.key] : null

				// Calculate inverse weight
				const inverseWeight = (10 - result?.rating) * inverseScoreWeight

				// Calculate weighted average
				const weightedAverage = (result?.rating * question.importanceWeight) + (result?.rating * question.urgencyWeight) + (result?.rating * inverseWeight)

				// Calculate total weight
				const totalWeight = question.importanceWeight + question.urgencyWeight + inverseWeight

				// Return updated question with calculated values
				return {
					...question,
					inverseWeight: inverseWeight,
					weightedAverage: weightedAverage,
					totalWeight: totalWeight,
					rating: result?.rating,
					comment: result?.comment,
				}
			})

			// Calculate total weight sum for the step
			const totalWeightSum = updatedQuestions?.reduce((sum, item) => sum + item.totalWeight, 0)

			// Calculate total weighted average sum for the step
			const totalWeightedAverageSum = updatedQuestions?.reduce((sum, item) => sum + item.weightedAverage, 0)

			// Calculate rating average for the step
			// @ts-ignore
			const ratingAverage = updatedQuestions?.reduce((sum, item) => sum + item.rating, 0) / updatedQuestions?.length

			// Return updated step with calculated values
			return {
				...step,
				questions: updatedQuestions,
				totalWeightSum: totalWeightSum,
				totalWeightedAverageSum: totalWeightedAverageSum,
				//@ts-ignore
				weightedRating: totalWeightedAverageSum / totalWeightSum,
				ratingAverage: ratingAverage
			}
		})

		// Log the merged data
		console.log(mergeData)

		// Update final results with merged data and form values
		finalResultsVar({
			location: findLocation(values.location),
			theater: findLocation(values.location).theater?.name,
			auditorium: findLocation(values.location).auditorium?.name,
			date: new Date(),
			controllerName: values.controllerName,
			controllerSurname: values.controllerSurname,
			controllerEmail: values.controllerEmail,
			steps: mergeData
		})

		// Set results state with merged data
		setResults(mergeData)

		// Open the results drawer
		setOpen(true)
	}
	const {token} = theme.useToken()
	const customIcons: Record<number, React.ReactNode> = {
		1: <ThumbDownIcon style={{color: token.colorError}}/>,
		2: <ThumbsUpDownIcon style={{color: token.colorWarning}}/>,
		3: <ThumbUpIcon style={{color: token.colorSuccess}}/>,
	};
	const activeStep = useReactiveVar(currentStepVar)
	const hideImages = useReactiveVar(hideImagesVar)
	const customIconsBinary: Record<number, React.ReactNode> = {
		1: <ThumbDownIcon style={{color: token.colorError}}/>, 2: <ThumbUpIcon style={{color: token.colorSuccess}}/>,
	};
	const onStepChange = (current: number) => {
		setCurrentStep(current)
		currentStepVar(qc[current - 1])
	}
	const [controllerName, setControllerName] = useState<string | null>(null)
	const [location, setLocation] = useState(findLocation([]))
	const statsForNerds = useReactiveVar(statsForNerdsVar)
	return (<Layout style={{minHeight: '100vh'}}>
		<Header>
			<Menu theme={'dark'} style={{height: '100%'}}>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%'}}>
					<Typography.Text strong
					                 style={{
						                 margin: 0, color: 'inherit'
					                 }}>
						{location?.auditorium ? location?.auditorium?.name : 'QC blue'}
						{currentStep > 0 && ` - ${activeStep.title}`}
					</Typography.Text>

				</div>
			</Menu>
		</Header>
		<Layout>
			<Content
				style={{marginLeft: 16, marginRight: 16, paddingTop: 16, minHeight: 'calc(100vh - 64px)',}}>
				<Row>
					<Col xs={{span: 24}} sm={{span: 18, offset: 3}} md={{span: 16, offset: 4}} lg={{span: 12, offset: 6}}
					     xl={{span: 10, offset: 7}} xxl={{span: 8, offset: 8}}>
						<StepsForm formRef={formRef}
						           onFinish={onFinish}

						           onFormChange={(c, a) => {
						           }}
						           stepsProps={{
							           direction: 'horizontal', responsive: false, onChange: onStepChange
						           }}
						           submitter={{
							           submitButtonProps: {
								           icon: <ArrowRightIcon/>, iconPosition: 'end'
							           }, resetButtonProps: {
								           icon: <ArrowLeftIcon/>
							           }, //onSubmit: (values) => window.scrollTo(0, 0), onReset: (values) => window.scrollTo(0, 0)

						           }}
						           current={currentStep}
						           onCurrentChange={onStepChange}
						           stepsFormRender={(dom, submitter) => {
							           return <div style={{height: '100%'}}>{dom}
								           <Affix offsetBottom={16} style={{marginTop: 16, marginBottom: 16}}>
									           <ProCard bordered><Space>{submitter}</Space></ProCard>
								           </Affix>
							           </div>
						           }}
						           containerStyle={{minWidth: '100%',}}

						>
							<StepsForm.StepForm stepProps={{icon: <HomeIcon/>}}
							>
								<ProCard style={{marginBlockEnd: 16}}>
									<Typography.Title level={2}>
										{controllerName ? `Hi ${controllerName},` : 'Hey there,'}
									</Typography.Title>
									<Typography.Paragraph>
										Let's get started with the Quality Check.<br/>
										First off, what's your name and how can we reach you?
									</Typography.Paragraph>
									<Space.Compact>
										<ProFormText
											name={'controllerName'}
											placeholder={'Name'}
											hasFeedback
											fieldProps={{
												onChange: (e) => setControllerName(e.target.value),
											}}
											rules={[{type: 'string'}, {required: true}]}
										/>
										<ProFormText
											name={'controllerSurname'}
											hasFeedback
											placeholder={'Surname'}
											rules={[{type: 'string'}, {required: true}]}
										/>
									</Space.Compact>
									<ProFormText
										name={'controllerEmail'}
										hasFeedback
										placeholder={'Email'}
										rules={[{type: "email"}, {required: true}]}
									/>
									<Typography.Paragraph>For which auditorium would you like to conduct the Quality
										check?</Typography.Paragraph>
									<ProFormCascader
										name={'location'}
										placeholder={'Select the Theater & Auditorium'}
										fieldProps={{
											options: locations.map((location) => ({
												value: location.id,
												label: `${location.name}(${location.state})`,
												children: location.children?.map((child) => ({
													value: child?.id, label: child?.name
												})) || []
											})), showSearch: true, onChange: (value) => {
												const loc = findLocation(value)
												return setLocation(loc)
											}
										}}
										rules={[{required: true}]}
									/>
									<ProFormDependency name={['location']}>
										{(value) => <>
											{value.location ? <> <ProCard
												title={location ? `${location.theater?.name} - ${location.auditorium?.name}` : '-'}
												subTitle={location ? `${location.theater?.address}, ${location.theater?.zipCode} ${location.theater?.city}` : '-'}
												bordered size={'small'}
												style={{marginBlockEnd: 24}}


											><ProCard split={'horizontal'}>
												<ProCard split={'vertical'}
												>
													<StatisticCard size={'default'}
													               statistic={{
														               title: 'Projector',
														               icon: <ProjectorIcon/>,
														               value: 'N/A',
														               description: [<StatisticCard.Statistic title={'4K'}
														                                                      value={'N/A'}/>],
													               }}/>
													<StatisticCard size={'default'}
													               statistic={{
														               title: 'Screen Server',
														               icon: <ServerIcon/>,
														               value: 'N/A',
														               description: [<StatisticCard.Statistic title={'HFR'}
														                                                      value={'N/A'}/>],

													               }}/>

												</ProCard>
												<ProCard split={'vertical'}>
													<StatisticCard size={'default'}
													               statistic={{
														               title: 'Audio',
														               icon: <SurroundSoundIcon/>,
														               value: 'N/A',
														               description: [<StatisticCard.Statistic title={'5.1'}
														                                                      value={'N/A'}/>,
															               <StatisticCard.Statistic title={'7.1'} value={'N/A'}/>,
															               <StatisticCard.Statistic title={'ATMOS'}
															                                        value={'N/A'}/>],

													               }}/>
													<StatisticCard size={'default'}
													               statistic={{
														               title: '3D', icon: <Video3DIcon/>, value: 'N/A'
													               }}/>

												</ProCard>
											</ProCard>
											</ProCard>
												<Typography.Paragraph>
													Select the playlist:
												</Typography.Paragraph>
												<Card bordered style={{marginBottom: 14}}>
													<Card.Meta avatar={<Avatar icon={<PlayBoxIcon/>}
													                           style={{backgroundColor: token.colorPrimary}}/>}
													           title={'ZZZ_QC_3D_5.1'}/>

												</Card>
												<Typography.Paragraph>
													on the screen server, then press play and go on the next
													step.<Typography.Link> Need help?</Typography.Link>
												</Typography.Paragraph> </> : <><ProCard bordered>
												<Skeleton/>
												<Skeleton/>
											</ProCard>
												<Skeleton paragraph={{rows: 1}}/>
												<Card bordered style={{marginBottom: 14}}>
													<Card.Meta avatar={<Avatar icon={<PlayBoxIcon/>}
													                           style={{backgroundColor: token.colorPrimary}}/>}
													           title={<Skeleton paragraph={{rows: 0, style: {margin: 0}}}
													                            style={{margin: 0}} title={{style: {margin: 0}}}/>}
													/>


												</Card>
											</>}


										</>}
									</ProFormDependency>
								</ProCard>

							</StepsForm.StepForm>
							{qc.map(step => (<StepsForm.StepForm stepProps={{icon: step.icon}} key={step.key}>
								{step.questions?.map((question, i) => <ProCard key={question.key} title={
									<Space>{question.icon}{question.question}</Space>}
								                                               bordered
								                                               headerBordered
								                                               collapsible
								                                               style={{
									                                               marginBlockEnd: 16,
								                                               }}
								                                               bodyStyle={{paddingBottom: 0}}
								                                               extra={question.tooltip && [<Tooltip
									                                               title={question.tooltip}><Button
									                                               size={'small'}
									                                               shape={'circle'}
									                                               icon={<HelpIcon/>}/></Tooltip>]}
								>
									{question.image && !hideImages &&
										<div style={{
											marginBottom: 16,
										}}>
											<Image src={question.image} alt={question.question} style={{
												borderRadius: token.borderRadius,
												boxShadow: token.boxShadow
											}}/></div>}
									{question.type === 'rating' && (<>
										<div>
											<ProFormRadio.Group
												name={[step.key, question.key, 'rating']}
												radioType={'button'}
												options={question.ratings.length === 3 ? question.ratings?.map((rating, index) => ({
													label: <>{customIcons[index + 1]}</>, value: rating,
												})) : question.ratings?.map((rating, index) => ({
													label: <>{customIconsBinary[index + 1]}</>, value: rating,
												}))}
												hasFeedback
												rules={[{required: true}]}
											/>
										</div>
										<ProFormDependency name={[step.key, question.key, 'rating']}>
											{(name) => <>
												{(name[step.key] && name[step.key][question.key] && (name[step.key][question.key]['rating'] !== 10)) && <>
													<div>
														<ProFormTextArea
															name={[step.key, question.key, 'comment']}
															//label='Remarks'
															hasFeedback
															placeholder={'Remarks'}
															rules={[{required: true}]}
														/>
													</div>
												</>}
											</>

											}
										</ProFormDependency>
									</>)}
									{statsForNerds && <>
										<Descriptions size={'small'} column={2}>
											<Descriptions.Item label={'Importance Weight'}>
												{question.importanceWeight}
											</Descriptions.Item>
											<Descriptions.Item label={'Urgency Weight'}>
												{question.urgencyWeight}
											</Descriptions.Item>
											<Descriptions.Item label={'ratings'}>
												{question.ratings?.join(', ')}
											</Descriptions.Item>

										</Descriptions>
									</>}

								</ProCard>)}
							</StepsForm.StepForm>))}
						</StepsForm>
					</Col>
				</Row>
				<Drawer
					title="Results"
					placement="bottom"
					open={open}
					height={'100%'}
					destroyOnClose={false}
					onClose={() => setOpen(false)}
				>
					<Results data={chartData} results={results}/>
				</Drawer>
				<FloatMenu/>
			</Content>
		</Layout>
	</Layout>);
}

export default App;
