import rawLocations from './rawLocation.json';

export type Maybe<T> = T | undefined;
export type Scalars = {
    ID: { input: string; output: string; }
    String: { input: string; output: string; }
    Boolean: { input: boolean; output: boolean; }
    Int: { input: number; output: number; }
    Float: { input: number; output: number; }
    DateTime: { input: any; output: any; }
    Gender: { input: any; output: any; }
    JSON: { input: any; output: any; }
    Upload: { input: any; output: any; }
};
export type Location = {
    __typename?: 'Location';
    address?: Maybe<Scalars['String']['output']>;
    children?: Maybe<Array<Maybe<Location>>>;
    city?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['Int']['output']>;
    metadata?: Maybe<Scalars['JSON']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    parent?: Maybe<Location>;
    //parts?: Maybe<Array<Maybe<Part>>>;
    state?: Maybe<Scalars['String']['output']>;
    //type?: Maybe<LocationType>;
    zipCode?: Maybe<Scalars['String']['output']>;
};


export const locations = [...rawLocations] as Location[];