import {FloatButton} from "antd";
import CogIcon from "@2fd/ant-design-icons/lib/Cog";
import GlassesIcon from "@2fd/ant-design-icons/lib/Glasses";
import {hideImagesVar, statsForNerdsVar, themeVar} from "../apollo/reactiveVar";
import ThemeLightDarkIcon from "@2fd/ant-design-icons/lib/ThemeLightDark";
import React from "react";
import {useReactiveVar} from "@apollo/client";
import FileImageRemoveIcon from "@2fd/ant-design-icons/lib/FileImageRemove";
import FileImageIcon from "@2fd/ant-design-icons/lib/FileImage";

export default function FloatMenu() {
	const hideImages = useReactiveVar(hideImagesVar)

	return (
		<FloatButton.Group
			trigger="click"
			type="primary"
			style={{insetInlineEnd: 24}}
			icon={<CogIcon/>}
		>
			<FloatButton icon={<GlassesIcon/>} tooltip={'Stats for nerds'}
			             onClick={() => statsForNerdsVar(!statsForNerdsVar())}/>
			<FloatButton onClick={() => hideImagesVar(!hideImagesVar())}
			             icon={!hideImages ? <FileImageRemoveIcon/> : <FileImageIcon/>}/>
			<FloatButton icon={<ThemeLightDarkIcon/>}
			             onClick={() => themeVar(themeVar() === 'dark' ? 'light' : 'dark')}/>
		</FloatButton.Group>
	)
}