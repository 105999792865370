import ProjectorScreenIcon from "@2fd/ant-design-icons/lib/ProjectorScreen";
import {ReactElement} from "react";
import ImageFrameIcon from "@2fd/ant-design-icons/lib/ImageFrame";
import SpeakerIcon from "@2fd/ant-design-icons/lib/Speaker";
import MagicStaffIcon from "@2fd/ant-design-icons/lib/MagicStaff";
import StretchToPageOutlineIcon from "@2fd/ant-design-icons/lib/StretchToPageOutline";
import RectangleOutlineIcon from "@2fd/ant-design-icons/lib/RectangleOutline";
import AngleRightIcon from "@2fd/ant-design-icons/lib/AngleRight";
import ArrowDownIcon from "@2fd/ant-design-icons/lib/ArrowDown";
import FocusFieldIcon from "@2fd/ant-design-icons/lib/FocusField";
import RulerSquareIcon from "@2fd/ant-design-icons/lib/RulerSquare";
import Video3DIcon from "@2fd/ant-design-icons/lib/Video3D";
import SurroundSound51Icon from "@2fd/ant-design-icons/lib/SurroundSound51";
import SurroundSound71Icon from "@2fd/ant-design-icons/lib/SurroundSound71";
import VolumeHighIcon from "@2fd/ant-design-icons/lib/VolumeHigh";

export interface QC {
    steps?: Step[];
    // added by post processing
    theater?: string
    auditorium?: string,
    location?: any,
    date?: Date
    time?: string
    controllerName?: string
    controllerSurname?: string
    controllerEmail?: string
}

export interface Step {
    title: string;
    icon?: ReactElement;
    key: string;
    description?: string;
    questions?: Question[];
    // added by post processing
    totalWeightSum?: number;
    totalWeightedAverageSum?: number;
    weightedRating?: number;
    ratingAverage?: number;
}

interface Question {
    title?: string;
    image?: string;
    icon?: ReactElement;
    question: string;
    tooltip?: string;
    key: string;
    type: 'boolean' | 'rating'
    ratings: [number, number, number?]
    weight?: [number, number, number?];
    importanceWeight: number;
    urgencyWeight: number;
    commentPlaceholder?: string;
    // added by post processing
    inverseWeight?: number;
    weightedAverage?: number;
    totalWeight?: number;
    rating?: number;
    comment?: string;
}

export const qc: Step[] = [{
    title: 'State of Screen', key: 'Screen', icon: <ProjectorScreenIcon/>, questions: [{
        question: 'Is the screen Stretched?',
        tooltip: 'A stretched screen is free of wrinkles, creases, and folds.',
        key: 'isStretched',
        image: '/placeholder.png',
        icon: <StretchToPageOutlineIcon/>,
        type: 'rating',
        ratings: [1, 10],
        importanceWeight: 1,
        urgencyWeight: 2
    }, {
        question: 'Is the screen clean?',
        tooltip: 'A clean screen is free of dust, dirt, scratches and smudges.',
        image: '/placeholder.png',
        key: 'isClean',
        icon: <RectangleOutlineIcon/>,
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 0.7,

    }, {
        question: 'The screen does not slack',
        tooltip: 'A sagging screen will make the tentioning system (eyelets) visible.',
        key: 'isSlacking',
        image: '/placeholder.png',
        icon: <ArrowDownIcon/>,
        type: "rating",
        ratings: [1, 5, 10],
        importanceWeight: .5,
        urgencyWeight: 0.2,
    }, {
        question: 'Is the masking straight?',
        tooltip: 'The masking on all side should be straight relative to the edges of the room',
        key: 'isMaskingStraight',
        image: '/placeholder.png',
        icon: <AngleRightIcon/>,
        type: "rating",
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 1,

    }

    ]
}, {
    title: 'State of Picture', key: 'Picture', icon: <ImageFrameIcon/>, questions: [{
        question: 'Does the picture fits the screen?',
        tooltip: 'A fitting picture is centered and fills the maximum of the visible screen',
        image: '/placeholder.png',
        icon: <StretchToPageOutlineIcon/>,
        key: 'isFitting',
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 1
    }, {
        question: 'Is the picture sharp?',
        tooltip: 'A sharp picture is free of blur',
        image: '/placeholder.png',
        icon: <FocusFieldIcon/>,
        key: 'isSharp',
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: 1.5,
        urgencyWeight: 1.5
    }, {
        question: 'Is the tolerance all the edges respected?',
        tooltip: 'The bars on the top and bottom of the screen should be visible',
        image: '/placeholder.png',
        icon: <RulerSquareIcon/>,
        key: 'isToleranceRespected',
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: .7
    }, {
        question: 'Are the edges of the picture parallel to the masking?',
        tooltip: 'The edges of the picture should be parallel to the masking',
        image: '/placeholder.png',
        key: 'isEdgesParallel',
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: .5,
        urgencyWeight: .7
    }]
}, {
    title: 'State of 3D', key: '3D', icon: <Video3DIcon/>, questions: [{
        question: 'Is there speckling?',
        tooltip: 'Speckling is the presence of white or colored dots on the screen',
        image: '/placeholder.png',
        key: 'isSpeckling',
        type: "rating",
        ratings: [1, 5, 10],
        importanceWeight: .7,
        urgencyWeight: .3
    }, {
        question: 'Is the Speckling uneven?',
        image: '/placeholder.png',
        key: 'isSpecklingEven',
        type: "rating",
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 1
    }, {
        question: 'Is the 3D effect visible?',
        image: '/placeholder.png',
        key: 'is3DVisible',
        type: "rating",
        ratings: [1, 5, 10],
        importanceWeight: 1.5,
        urgencyWeight: 1.5
    }, {
        question: 'is the 3D polarity correct?',
        tooltip: 'Inverted polarity causes the background objects to appear in the foreground and vice versa.',
        image: '/placeholder.png',
        key: 'isPolarityCorrect',
        type: "rating",
        ratings: [1, 5, 10],
        importanceWeight: 1.5,
        urgencyWeight: 1.5

    }]
}, {
    title: 'State of Audio', key: 'Audio', icon: <SpeakerIcon/>, questions: [{
        question: '5.1: are the channels correctly assigned?',
        tooltip: 'The sound should come from the correct speaker(L,R,C,LS,RS,LFE)',
        image: '/placeholder.png',
        icon: <SurroundSound51Icon/>,
        key: 'is51ChannelsAssigned',
        type: "rating",
        ratings: [1, 5, 10],
        importanceWeight: 1.6,
        urgencyWeight: 1.6
    }, {
        question: '7.1: are the channels correctly assigned?',
        tooltip: 'The sound should come from the correct speaker(L,R,C,LS,RS,BLS, BRS,LFE)',
        image: '/placeholder.png',
        icon: <SurroundSound71Icon/>,
        key: 'is71ChannelsAssigned',
        type: "rating",
        ratings: [1, 5, 10],
        importanceWeight: 1.6,
        urgencyWeight: 1.6
    }, {
        question: 'Do all the channels sound equally loud?(L,C,R, and LS,RS,BSR, BSL)',
        tooltip: 'The sound should be equally loud from all speakers',
        image: '/placeholder.png',
        icon: <VolumeHighIcon/>,
        key: 'isChannelsLeveled',
        type: "rating",
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 1
    }, {
        question: 'Is the sound clear and intelligible?',
        tooltip: 'The sound should be clear and understandable',
        image: '/placeholder.png',
        key: 'isIntelligible',
        type: "rating",
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 1
    }]
}, {
    title: 'Experience', key: 'Experience', icon: <MagicStaffIcon/>, questions: [ {
        question: 'THX Eclipse',
        image: '/placeholder.png',
        key: 'isTHXEclipse',
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 1
    }, {
        question: 'Dolby Shere',
        image: '/placeholder.png',
        key: 'isDolbyShere',
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 1

    }, {
        question: 'THX Amazing',
        image: '/placeholder.png',
        key: 'isTHXAmazing',
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 1
    }, {
        question: 'The Hobbit',
        image: '/placeholder.png',
        key: 'isHobbit',
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 1
    }, {
        question: 'Batman VS: Superman',
        image: '/placeholder.png',
        key: 'isBatmanVsSuperman',
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: 1,
        urgencyWeight: 1

    }, {
        question: 'Foyer Music',
        key: 'isFoyerMusic',
        image: '/placeholder.png',
        type: 'rating',
        ratings: [1, 5, 10],
        importanceWeight: .4,
        urgencyWeight: .3,
    }]
}

]

export const inverseScoreWeight = 1
