import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ConfigProvider, theme} from "antd";
import enUS from 'antd/locale/en_US';
import {useReactiveVar} from "@apollo/client";
import {themeVar} from "./apollo/reactiveVar";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Index/>)

function Index() {
    const mode = useReactiveVar(themeVar)

    return <React.StrictMode>

        <ConfigProvider locale={enUS}
                        theme={{algorithm: mode === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm}}

        >
            <App/>
        </ConfigProvider>
    </React.StrictMode>
}