import {Radar} from "@ant-design/plots";
import {Step} from "../qc";
import {Button, Descriptions, Space, theme, Tooltip, Typography} from "antd";
import React, {useEffect, useState} from "react";
import useTheme from "antd/es/config-provider/hooks/useTheme";
import {ProCard} from "@ant-design/pro-components";
import ScaleUnbalancedIcon from "@2fd/ant-design-icons/lib/ScaleUnbalanced";
import ScaleBalanceIcon from "@2fd/ant-design-icons/lib/ScaleBalance";
import {round} from "lodash";
import {useReactiveVar} from "@apollo/client";
import {finalResultsVar, statsForNerdsVar, themeVar} from "../apollo/reactiveVar";
import ThumbDownIcon from "@2fd/ant-design-icons/lib/ThumbDown";
import ThumbsUpDownIcon from "@2fd/ant-design-icons/lib/ThumbsUpDown";
import ThumbUpIcon from "@2fd/ant-design-icons/lib/ThumbUp";
import ContentSaveIcon from "@2fd/ant-design-icons/lib/ContentSave";

interface Point {
    name?: string;
    star?: number;
}

interface ResultsProps {
    data?: Point[];
    results?: Step[];
}

export default function Results(props: ResultsProps) {
    const [data, setData] = useState([]);
    const statsForNerds = useReactiveVar(statsForNerdsVar)
    const mode = useReactiveVar(themeVar)
    const {token} = theme.useToken()
    const customIcons: Record<number, React.ReactNode> = {
        1: <ThumbDownIcon style={{color: token.colorError}}/>,
        2: <ThumbsUpDownIcon style={{color: token.colorWarning}}/>,
        3: <ThumbUpIcon style={{color: token.colorSuccess}}/>,
    };
    const t = useTheme()
    useEffect(() => {
        const weightedData = props.results?.map((d) => ({
            name: d.title, type: 'Weighted Average', //@ts-ignore
            weightedRating: parseFloat(d?.weightedRating.toFixed(2))
        })) || []
        const averageData = props.results?.map((d) => ({
            name: d.title, type: 'Average', weightedRating: d?.ratingAverage
        })) || []
        //@ts-ignore
        setData([...weightedData, ...averageData]);
    }, [props.results]);
    const config = {
        //data: props.results?.map((d) => ({name:d.title, type:'Weighted Average', weightedRating: d?.weightedRating})),
        theme: mode, xField: 'name', yField: 'weightedRating', shapeField: 'smooth', colorField: 'type', //theme:t.theme.id === 'dark' ? 'dark' : 'light',
        area: {
            style: {
                fillOpacity: 0.2,
            },
        }, scale: {
            x: {
                padding: 0.5, align: 0,
            }, y: {
                // nice: true,
                tickCount: 10, domainMax: 10,

            },
        }, axis: {
            x: {
                grid: true,
            }, y: {
                // gridAreaFill: 'rgba(0, 0, 0, 0.04)',
                label: true, //title: true,
            },
        },
    };

    return (<div>
        <Radar {...config} data={data}/>
        <Space><Button href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(finalResultsVar()))}`}
                       download={`${finalResultsVar().theater}-${finalResultsVar().auditorium}_${finalResultsVar().date?.toLocaleDateString()}`} icon={<ContentSaveIcon/>}>Save results</Button></Space>

        {props.results?.map(step => <div key={step.key}>
            <ProCard collapsible defaultCollapsed={true} title={<Space>{step.icon}{step.title}</Space>} bordered
                     style={{marginBlockEnd: 16}}
                     extra={<Space><Tooltip
                         title={'Average Weighting'}><ScaleBalanceIcon/>{step.ratingAverage ? round(step.ratingAverage, 2) : '-'}
                     </Tooltip><Tooltip
                         title={'Weighted  Average'}><ScaleUnbalancedIcon/> {step.weightedRating ? round(step.weightedRating, 2) : '-'}
                     </Tooltip></Space>}
            >
                {step.questions?.map(question => <div key={question.key}>
                    <Space>
                        <Typography.Text
                            strong>{question.question}
                        </Typography.Text>{customIcons[question.ratings?.findIndex(r => r === question.rating) + 1]}

                    </Space>
                    {question.comment && <Typography.Paragraph italic>
                        {question.comment}
                    </Typography.Paragraph>}

                    {statsForNerds && <Descriptions column={2}>
                        <Descriptions.Item
                            label={'Importance Weight'}>{question.importanceWeight}</Descriptions.Item>
                        <Descriptions.Item label={'Urgency Weight'}>{question.urgencyWeight}</Descriptions.Item>
                        <Descriptions.Item label={'inverse Weight'}>{question.inverseWeight}</Descriptions.Item>
                        <Descriptions.Item label={'Total Weight'}>{question.totalWeight}</Descriptions.Item>
                        <Descriptions.Item label={'Rating'}>{question.rating}</Descriptions.Item>
                        <Descriptions.Item label={'Weighted Average'}>{question.weightedAverage}</Descriptions.Item>
                    </Descriptions>}
                </div>)}
            </ProCard>
        </div>)}
    </div>);
}